'use client'
import { ColorFromAPI, ColorType, SampleImage, SizeFromAPI } from "@/utils/APIRouteTypes"
import { getColor, getSize } from "@/utils/helper"
import { useSearchParams } from "next/navigation"
import { createContext, ReactNode, useContext, useEffect, useMemo } from "react"
createContext

type NonUrlContextType = {
    selectedColor: ColorFromAPI | null
    sampleImages: SampleImage[]
    selectedSize: SizeFromAPI
    colorMode: ColorType
}

const NonUrlContext = createContext<NonUrlContextType | undefined>(undefined)

type ProviderProps = {
    children: ReactNode,
    colors: ColorFromAPI[],
    sizes: SizeFromAPI[],
    sampleImages: SampleImage[],
}

export const NonUrlContextProvider = (props: ProviderProps) => {
    const searchParams = useSearchParams()

    const { color, size } = useMemo(() => {
        return {
            color: getColor(props.colors, searchParams.get('color')),
            size: getSize(props.sizes, searchParams.get('size'))
        }
    }, [searchParams.get('color'), searchParams.get('size')])


    return (
        <NonUrlContext.Provider value={{
            selectedColor: color,
            sampleImages: props.sampleImages,
            selectedSize: size,
            colorMode: color?.colorType || ColorType.BRIGHT
        }}>
            {props.children}
        </NonUrlContext.Provider>
    )
}

export const useNonUrlContext: () => NonUrlContextType = () => {
    const context = useContext(NonUrlContext)
    if (context === undefined) {
        const defaultSize = {
            abbreviation: 'M',
            name: 'Medium',
            cameraBox: null,
            isCircular: false,
            id: -1
        } as SizeFromAPI

        return ({
            selectedColor: null,
            sampleImages: [],
            colorMode: ColorType,
            selectedSize: defaultSize
        }) as unknown as NonUrlContextType
    }
    return context
}