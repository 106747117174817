'use client'
import { ColorType } from '@/utils/APIRouteTypes'
import React, { createContext, SetStateAction, useEffect, useState } from 'react'

type SessionStorageContextType = {
    numImagesGenerated: number
    isFeedbackSubmitted: boolean
    isGeneratingImage: boolean
    predictionID?: number
    prompt?: string
    colorMode?: ColorType
    predictionPage?: string
}

const SessionStorageKey = 'sessionStorageContext'

const SessionStorageContext = createContext<[SessionStorageContextType, React.Dispatch<SetStateAction<SessionStorageContextType>>] | null>(null)

export default function SessionStorageContextProvider({ children }: { children: React.ReactNode }) {
    const defaultValue = {
        numImagesGenerated: 0,
        isFeedbackSubmitted: false,
        isGeneratingImage: false
    }

    const [value, setValue] = useState<SessionStorageContextType>(defaultValue)

    useEffect(() => {
        const storedValue = sessionStorage.getItem(SessionStorageKey)
        if (storedValue) {
            const x = JSON.parse(storedValue) as SessionStorageContextType
            setValue(x)
        }
    }, [])

    useEffect(() => {
        if (JSON.stringify(value) !== JSON.stringify(defaultValue))
            sessionStorage.setItem(SessionStorageKey, JSON.stringify(value))
    }, [value])

    return (
        <SessionStorageContext.Provider value={[value, setValue]}>
            {children}
        </SessionStorageContext.Provider>
    )
}


export const useSessionStorageContext = () => {
    const context = React.useContext(SessionStorageContext)
    if (context === null) {
        throw new Error('useCommonContext must be used within a CommonContextProvider')
    }
    return context
}