import { CartItemType, ColorFromAPI, NCProductListItem, ProductDetail, ProductItemCategory, ProductListItem, SizeFromAPI, WishListItem } from "./APIRouteTypes";
import { Properties } from "./properties";

export const getGenderFromCategory = (category: ProductItemCategory) => {
    switch (category) {
        case ProductItemCategory.BOYS:
            return 'male'
        case ProductItemCategory.GIRLS:
            return 'female'
        case ProductItemCategory.MEN:
            return 'male'
        case ProductItemCategory.WOMEN:
            return 'female'
        default:
            return 'unisex'
    }
}

export const getImageInitialProps = (productDetails: ProductDetail, side: 'front' | 'back' = 'front') => {
    const boundingBox = !productDetails.boundingBoxBack ? productDetails.boundingBoxFront : side === 'back' ? productDetails.boundingBoxBack : productDetails.boundingBoxFront

    const trueHeight = productDetails.imgHeight ? boundingBox.height * productDetails.imgHeight : boundingBox.height
    const trueWidth = productDetails.imgWidth ? boundingBox.width * productDetails.imgWidth : boundingBox.width

    const y = ((trueHeight - trueWidth) / 2) / trueHeight
    const x = ((trueWidth - trueHeight) / 2) / trueWidth

    if (productDetails.category === ProductItemCategory.ACCESSORIES) {
        return {
            x: Math.min(0, x),
            y: Math.min(0, y),
            scale: Math.max(Properties.defaultImgScale, Properties.defaultImgScale * (trueHeight / trueWidth)),
        }
    }

    return {
        x: 0,
        y: y,
        scale: Properties.defaultImgScale,
    }
}

export const getColor = (colors: ColorFromAPI[], colorString: string | undefined | null) => {
    if (colors.length == 0)
        return null

    return colorString ? colors.find((color) => color.name === colorString) || colors[0] : colors[0]
}

export const getSize = (sizes: SizeFromAPI[], sizeString: string | undefined | null) => {
    const defaultSize = sizes[Math.min(1, sizes.length - 1)]
    return sizeString ? sizes.find((size) => size.abbreviation === sizeString) || defaultSize : defaultSize
}

export const addApostrophe = (str: string) => {
    if (str.endsWith('s')) {
        return str + "'";
    }
    return str + "'s";
}

export const getCorrectProductDetails = (item: WishListItem) => {
    if (item.productDetails) {
        return { ...item.productDetails, id: item.productID, productType: "Customizable" }
    }
    return { ...item.nc_productDetails, id: item.nc_productID, productType: "NonCustomizable" }
}

export const getLinkForProduct = (item: WishListItem, from?: string) => {
    const p = getCorrectProductDetails(item)
    const sp = new URLSearchParams()
    sp.append('color', item.selectedColor?.name || 'White')
    if (item.selectedSize)
        sp.append('size', item.selectedSize.abbreviation)
    if (item.context)
        sp.append('context', encodeURIComponent(JSON.stringify(item.context)))
    if (from)
        sp.append('from', from)

    const base = item.productID ? Properties.routes.PRODUCT_LIST : Properties.routes.NC_PRODUCT_LIST

    const href = base + '/' + p.slug + '?' + sp.toString()

    return href
}
