'use client'
import { Button } from "@/components/ui/button"
import { Properties } from '@/utils/properties'
import Link from 'next/link'
import { useState } from 'react'
import ImageViewModal from './generatedImageViewModal'
import { ImageGenerateItem } from './imageGenerateItem'

export default function MainPageImageGenerator() {
    const [openModal, setOpenModal] = useState(false)
    const [modalSrc, setModalSrc] = useState<{ src: string }>({ src: '' })

    const setCurrentImage = (n: string) => {
        setModalSrc({ src: n })
    }

    const handleImageClick = (src: string) => {
        setModalSrc({
            src: src,
        })
        setOpenModal(true)
    }

    return (
        <>
            <ImageGenerateItem selectedImages={[]} handlePromptImageClick={handleImageClick} />
            <ImageViewModal open={openModal} setOpen={setOpenModal} currentImage={modalSrc.src} setCurrentImage={setCurrentImage} >
                <Button asChild className='w-full'>
                    <Link href={Properties.routes.PRODUCT_LIST + '?image=' + encodeURIComponent(JSON.stringify(modalSrc))} id='use-img-to-customize' data-track="Use Image to Customize">
                        Use to customize
                    </Link>
                </Button>
            </ImageViewModal>
        </>
    )
}
